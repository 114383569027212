


























// CORE
import { Component, Vue } from 'vue-property-decorator'

// LIBRARIES
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'


extend('required', {
  ...required,
  message: 'Это обязательное поле',
})

extend('engOnlyEmail', {
  message: 'Введите действительный e-mail',
  validate: (value: string) => {
    const regExpEngEmail = /^(([a-zA-Z\-0-9_^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !!value.toString().match(regExpEngEmail)
  },
})

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class RecoveryPasswordEmailForm extends Vue {
  private email = ''

  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          this.$emit('click:submit', this.email)
        }
      })
  }
}
