



































































































































































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// MIXINS
import NotifyMixin from '@/mixins/NotifyMixin'

// LIBRARIES
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, confirmed } from 'vee-validate/dist/rules'

// TYPES
import { IRegisterForm, NameValueResource } from '@/store/types'

// COMPONENTS
import ExpansionMessageCard from '@/components/cards/ExpansionMessageCard.vue'

extend('confirmed', {
  ...confirmed,
  message: 'Поля должны совпадать',
})

extend('required', {
  ...required,
  message: 'Это обязательное поле',
})

extend('engOnlyEmail', {
  message: 'Введите действительный e-mail',
  validate: (value: string) => {
    const regExpEngEmail = /^(([a-zA-Z\-0-9_^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !!value.toString().match(regExpEngEmail)
  },
})

extend('passwordRequirements', {
  message: 'Пароль не соответствует требованиям',
  validate: (value: string) => {
    const regExpPassword = /^(?=.*[0-9])(?=.*[!@#$%^<>&*;:\\\-,.?/'|_=+)("[\]}{№])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^<>&*;:\\\-,.?/'|_=+)("[\]}{№]{10,}$/
    return !!value.toString().match(regExpPassword)
  },
})

@Component({
  components: {
    ExpansionMessageCard,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class RegistrationForm extends Mixins(NotifyMixin) {
  private isFleet = false
  private role: NameValueResource | null = null
  private showPassword = false

  readonly roleOptions: NameValueResource[] = [
    {
      name: 'Водитель',
      value: 'driver',
    },
    {
      name: 'Управляющий автопарком',
      value: 'fleet',
    },
    {
      name: 'Механик',
      value: 'mechanic',
    },
  ]

  protected form: IRegisterForm = {
    policy: false,
    agreement: false,
    email: '',
    order: '',
    password: '',
    passwordConfirmation: '',
    remember: false,
    partnerIds: [],
    vin: '',
    vinConfirmation: '',
  }

  private isRequired = false

  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result && this.form.agreement && this.form.policy) {
          this.$emit('click:create', this.form)
        } else if (result && this.form.policy) {
          this.notifyError('Примите условия соглашения')
        } else if (result) {
          this.notifyError('Согласитесь с политикой конфиденциальности')
        }
      })
  }

  private mounted () {
    this.role = this.roleOptions[0]
  }

  // Это нужно для того чтобы при смене роли появивщийся Combobox не считался Error сразу по появлению
  // @Watch('isFleet')
  // private isFleetWatcher (value: boolean) {
  //   this.$nextTick(() => {
  //     this.isRequired = value
  //   })
  // }
}
