import { render, staticRenderFns } from "./TireServiceOrderCreate.vue?vue&type=template&id=d9d03a40&scoped=true&lang=pug&"
import script from "./TireServiceOrderCreate.vue?vue&type=script&lang=ts&"
export * from "./TireServiceOrderCreate.vue?vue&type=script&lang=ts&"
import style0 from "./TireServiceOrderCreate.vue?vue&type=style&index=0&id=d9d03a40&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9d03a40",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCombobox,VTextarea})
