
























































































// CORE
import { Component, Prop, Vue } from 'vue-property-decorator'

// LIBRARIES
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, regex, alpha } from 'vee-validate/dist/rules'
import { omit } from 'lodash'

// INTERFACES
import { ICaracterModalOptions, UserAuthResource } from '@/store/types'

// COMPONENTS
import SmallModalFooter from '@/components/modals/components/SmallModalFooter.vue'

extend('required', {
  ...required,
  message: 'Это обязательное поле',
})

extend('alpha', {
  ...alpha,
  message: 'Поле может содержать только буквы',
})

extend('regex', {
  ...regex,
  message: 'Введите действительный номер',
})

extend('engOnlyEmail', {
  message: 'Введите действительный e-mail',
  validate: (value: string) => {
    const regExpEngEmail = /^(([a-zA-Z\-0-9_^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !!value.toString().match(regExpEngEmail)
  },
})

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    SmallModalFooter,
  },
})
export default class CharacterForm extends Vue {
  @Prop({ required: true })
  private options!: ICaracterModalOptions

  private regExpPhone = /^\+?[78]\s[-(]?\d{3}\)?-?[\s]?\d{3}-?\d{2}-?\d{2}$/

  private form = {
    surname: '',
    name: '',
    patronymic: '',
    email: '',
    personalPhone: '',
  }

  private get characterInfo (): UserAuthResource {
    return omit(this.options, 'formPartId', 'workPhone', 'formPartName')
  }

  private mounted () {
    this.form = {
      ...this.form,
      ...this.characterInfo,
    }
  }

  private handleSaveForm () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          this.$bus.$emit(`characterFormChange-${this.options.formPartId}`, this.form)
          this.$closeModal()
        }
      })
  }
}
