





























































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// LIBRARIES
import { DateTime } from 'luxon'

// COMPONENTS
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import RequestFormPartWrapper from '@/components/RequestFormPartWrapper.vue'
import CharacterCard from '@/components/cards/CharacterCard.vue'
import ControlsRequestFormPart from '@/components/forms/parts/ControlsRequestFormPart.vue'
import QuartersFormPart from '@/components/forms/parts/QuartersFormPart.vue'
import CaptionCard from '@/components/cards/CaptionCard.vue'

// INTERFACES
import {
  AuthorRequest,
  CreateActOfReconciliationRequest,
  UserTypeEnum,
} from '@/store/types'

// STORE
import FleetOrderModule from '@/store/modules/fleet/order'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import NotifyMixin from '@/mixins/NotifyMixin'

@Component({
  components: {
    DefaultLayout,
    ValidationProvider,
    ValidationObserver,
    CharacterCard,
    ControlsRequestFormPart,
    RequestFormPartWrapper,
    QuartersFormPart,
    CaptionCard,
  },
})
export default class ActOfReconciliationOrderCreate extends Mixins(
  SystemMixin,
  NotifyMixin,
) {
  private rules = {
    count: (value: string) => value.length <= 500,
  }

  private selectedPeriod = ''

  private form: CreateActOfReconciliationRequest = {
    author: {} as AuthorRequest,
    date: new Date().toISOString().substr(0, 10),
    from: '',
    to: '',
    comment: '',
  }

  private isShowComment = false

  private get isNotCompleted (): boolean {
    if (!this.form.to) return false

    const toDate = DateTime.fromISO(this.form.to)
    const now = DateTime.now().startOf('day')

    // Проверяем, что дата окончания периода не в будущем
    return toDate > now
  }

  private created () {
    FleetOrderModule.getOrderDictionaries({
      entity: +this.entity,
      params: { hasRepair: false },
    })
  }

  private handlePeriodChange (value: string[]) {
    if (Array.isArray(value) && value.length === 2) {
      const [from, to] = value
      this.form.from = from
      this.form.to = to
    }
  }

  private handleCloseComment () {
    this.isShowComment = false
    this.form.comment = ''
  }

  private async handleSubmit () {
    const form = this.$refs.form as any
    const isValid = await form.validate()

    if (!isValid) return

    try {
      this.form.author.type =
        this.role === 'fleet' || this.role === 'mechanic'
          ? ('manager' as UserTypeEnum)
          : ('driver' as UserTypeEnum)

      const response = await FleetOrderModule.sendActOfReconciliation({
        entity: +this.entity,
        body: this.form,
      })

      this.$openModal('OrderSendSuccessModal', 'big', {
        route: {
          name: `view-order.${response.type}`,
          params: { orderId: response.id },
        },
      })

      this.$router.push({ name: 'orders' })
    } catch (error) {
      this.notifyError(
        'Заявка не отправлена. Необходимо заполнить все обязательные поля.',
      )
    }
  }
}
