















































































































// CORE
import { Component } from 'vue-property-decorator'

// LIBRARIES
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, regex, alpha } from 'vee-validate/dist/rules'

// INTERFACES
import { ProfileRequestBody, UserAuthResource } from '@/store/types'

// COMPONENTS
import SmallModalFooter from '@/components/modals/components/SmallModalFooter.vue'

// STORE
import ProfileModule from '@/store/modules/profile'

// MIXINS
import NotifyMixin from '@/mixins/NotifyMixin'

extend('required', {
  ...required,
  message: 'Это обязательное поле',
})

extend('alpha', {
  ...alpha,
  message: 'Поле может содержать только буквы',
})

extend('regex', {
  ...regex,
  message: 'Введите действительный номер',
})

extend('engOnlyEmail', {
  message: 'Введите действительный e-mail',
  validate: (value: string) => {
    const regExpEngEmail = /^(([a-zA-Z\-0-9_^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !!value.toString().match(regExpEngEmail)
  },
})

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    SmallModalFooter,
  },
})
export default class PersonForm extends NotifyMixin {
  private regExpPhone = /^\+?[78]\s[-(]?\d{3}\)?-?[\s]?\d{3}-?\d{2}-?\d{2}$/
  private regExpPhoneWithCode = /^\+?[78]\s[-(]?\d{3}\)?-?[\s]?\d{3}-?\d{2}-?\d{2}\s-\s\d{0,4}$/

  private form: ProfileRequestBody = {
    surname: '',
    name: '',
    patronymic: '',
    email: '',
    phone: '',
    phoneCode: '',
  }

  private formValide: any = {
    surname: false,
    name: false,
    patronymic: false,
    email: false,
    phone: false,
    phoneCode: false,
  }

  private get userInfo (): UserAuthResource {
    return ProfileModule.userResource.user
  }

  private mounted () {
    this.form = {
      surname: this.userInfo.surname ?? '',
      name: this.userInfo.name ?? '',
      patronymic: this.userInfo.patronymic ?? '',
      email: this.userInfo.email ?? '',
      phone: this.userInfo.personalPhone ?? '',
      phoneCode: this.userInfo.workPhone ?? '',
    }
  }

  private handleSaveForm () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          const data = { ...this.form }

          if (data.phoneCode) {
            data.phoneCode = data.phoneCode.replace(' - ', 'p')
          }

          for (const prop in data) {
            if (!(data as any)[prop]) {
              (data as any)[prop] = ''
            }
          }

          ProfileModule.changeProfile(data)
            .then(() => {
              this.$closeModal()
            })
            .catch((error) => {
              let message = ''

              for (const key in error.response.data.errors) {
                this.formValide[key] = true

                if (!message) {
                  const err = error.response.data.errors[key]
                  message = Array.isArray(err) ? err[0] : err
                }
              }

              this.notifyError(message)
            })
        }
      })
  }
}
